<template>
    <div class="mask" v-if="setFlag">
        <div class="slider">
            <div class="slider_close" @click="hideMask"><img src="@/assets/imgs/shut.png" alt=""></div>
            <div class="title">表格设置 <img class="title_close"  src="@/assets/imgs/close.png" alt="" @click="hideMask"></div>
            <div class="content table_content">
                <div class="content_title">
                    <a-checkbox :indeterminate="indeterminate" :checked="checkAll" @change="onCheckAllChange">
                        请选择您重点查看的信息
                    </a-checkbox>
                </div>
                <div class="content_info">
                    <a-checkbox-group v-model="checkedList" :options="all" @change="onChange" /> 
                </div>
            </div>
            <div class="save"><a-button class="save_btn" type="primary" @click="saveSet">保存</a-button></div>
        </div>
    </div>
</template>
<script>
import $ from "jquery";
import {requestXml} from '../assets/js/request';
// const all = [
//     { title: '姓名', dataIndex: 'name'},
//     { title: '公司', dataIndex: 'company'},
//     { title: '标签', dataIndex: 'tags'}
// ];
// 传给表格表头中显示的
var tableCheckedList;
export default {
    props: {
        setFlag: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            checkedList: ["姓名","公司名称","标签","会员等级"],
            indeterminate: false,
            checkAll: false,
            all: ["姓名","公司名称","标签","会员等级","积分","来源","联系电话","操作"],
        };
    },
    mounted: function(){
        
    },
    watch: {
        // setFlag(val){
        //     if(val == true){
        //         this.getTable();
        //     }
        // }
    },
    methods: {
        // 全选
        onChange(checkedList) {
            this.indeterminate = !!checkedList.length && checkedList.length < this.all.length;
            this.checkAll = checkedList.length === this.all.length;
        },
        onCheckAllChange(e) {
            console.log(e.target.checked)
            // 选中的放到一个数组
            var checked = [];
            for(let i=0;i<this.all.length;i++){
               checked.push(this.all[i]["name"]);
            }
            Object.assign(this, {
                checkedList: e.target.checked ? checked : [],
                indeterminate: false,
                checkAll: e.target.checked,
            });
        },
        // 关闭
        hideMask(){
            this.$emit('updateFlag', false);
        },
        // 获取 客户列表显示列
        getTable: function(){
            requestXml("/scrm/corp/getfield?type=field","GET",(res) => {
                // 将全部的字段和选中的字段分别放入一个新数组再赋值
                var newCheckedList = [];
                tableCheckedList = [];
                for(let i=0;i<res.length;i++){
                    res[i].value = res[i].name;
                    res[i].label = res[i].text;
                    var obj = {};
                    if(res[i].show == true){
                        newCheckedList.push(res[i].name);
                        obj.title = res[i].text;
                        obj.dataIndex = res[i].name;
                        obj.type = res[i].type;
                        tableCheckedList.push(obj);
                    }
                }
                this.all = res;
                this.checkedList = newCheckedList;
                if(this.all.length == this.checkedList.length){
                    this.checkAll = true;
                }
                // this.$emit('updateSet', tableCheckedList);
            })
        },
        saveSet(){
            // 设置表格显示字段
            var field = "";
            var fieldArr = [];
            tableCheckedList = [];
            for(let i=0;i < this.all.length;i++){
                var obj = {};
                if($.inArray(this.all[i]["name"], this.checkedList) != -1 ){
                    fieldArr.push(this.all[i]["name"]);
                    field = fieldArr.join(",");

                    obj.title = this.all[i].text;
                    obj.dataIndex = this.all[i].name;
                    obj.type = this.all[i].type;
                    tableCheckedList.push(obj);
                }
            }
            if(field == ""){
                this.$message.info("选择列不能为空");
                return false;
            }else{
                // 设置表格选中字段
                requestXml("/scrm/corp/setField","POST",(res) => {
                    if(res == "success"){
                        this.$emit('updateFlag', false);
                        this.$emit('updateSet', tableCheckedList);
                    }
                },{"field": field})
            }
        }
    },
};
</script>
<style>
    .mask .slider .table_content label{
        margin: 1rem;
    }
    .mask .slider .table_content label{
        display: block;
    }
    
    .mask .slider .content .content_title{
        color: #202020;
    }
    .mask .slider .content .content_title label{
        font-size: 3rem;
    }
    .mask .slider .content .content_info{
       margin: 1rem 0 1rem 4rem;
       color: #666;
    }
    .mask .slider .content .content_info label{
        font-size: 2.8rem;
    }

    @media screen and (min-width: 750px){
        .mask .slider .content .content_title label{
            font-size: 18px
        }
        .mask .slider .content .content_info label{
            font-size: 16px;
        }
    }
</style>
