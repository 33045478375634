<template>
    <div class="mask" v-if="screenFlag">
        <div class="slider">
            <div class="slider_close" @click="hideMask"><img src="@/assets/imgs/shut.png" alt=""></div>
            <div class="title"> 
                {{name}}
                <img  class="title_close" src="@/assets/imgs/close.png" alt="" @click="hideMask">
            </div>
            <div class="content">
                <div v-for="(item,index) in data" :key="index">
                    <div class="screen_content"  v-if="item.options" :class="[item.isChecked == true ? '' : 'tag_content']">
                        <a-checkbox @change="isChecked($event,index)" v-model="item.isChecked"></a-checkbox>
                        <label class="info" for="">{{item.text}}</label>
                        <div v-if="item.isChecked">
                            <a-select size="large" v-model="item.checked" >
                                <a-select-option v-for="(item1) in item.options" :key="item1[0]" :value="item1[0]">{{item1[1]}}</a-select-option>
                            </a-select>
                            
                            <!-- 为空不为空 没有输入框 -->
                            <template v-if="item.checked == 'isnull' || item.checked == 'not isnull'"></template>
                            <!-- 包含不包含 一个输入框 -->
                            <template v-else-if="item.checked == 'like' || item.checked == 'not like'">
                                <!-- 输入框 -->
                                <a-input size="large" placeholder="请输入关键字" v-model="item.value"/>      
                            </template>
                            <!-- 包括不包括 -->
                            <template v-else-if="item.checked == 'find_in_set' || item.checked == 'no find_in_set'">
                                <!-- 多选标签，下拉框多选 -->
                                <a-select size="large" v-if="item.type == 'checkTag' && item.options != null || item.type == 'selects' && item.options != null" v-model="item.value">
                                    <a-select-option v-for="(item2,index2) in item.values" :key="index2" :value="item2.value">{{item2.value}}</a-select-option>
                                </a-select>
                            </template>
                            <!-- 等于不等于 -->
                            <template v-else-if="item.checked == '<>' || item.checked == '='"  >
                                <!-- 数字 -->
                                <a-input size="large" v-if="item.type == 'number'" v-model="item.value"/>
                                <!-- 单选标签，下拉框单选 -->
                                <a-select size="large" v-else-if="item.type == 'radioTag' && item.options != null || item.type == 'select' && item.options != null" v-model="item.value">
                                    <a-select-option v-for="(item2,index2) in item.values" :key="index2" :value="item2.value">{{item2.value}}</a-select-option>
                                </a-select>
                            </template>
                            <!-- 大于小于 -->
                            <template v-else-if="item.checked == '<=' || item.checked == '>='"  >
                                <!-- 数字 -->
                                <a-input size="large" v-if="item.type == 'number'" v-model="item.value"/>
                                <!-- 日期 -->
                                <template size="large" v-if="item.type == 'date'"  >
                                    <a-date-picker size="large" v-model="item.value" />
                                </template>
                                <!-- 时间 -->
                                <template v-if="item.type == 'time'"  >
                                    <a-time-picker size="large" v-model="item.value" />
                                </template>
                                <!-- 日期+时间 -->
                                <template v-if="item.type == 'dateTime'"  >
                                    <a-date-picker size="large" show-time v-model="item.value" />
                                </template>
                            </template>
                            <!-- 介于 -->
                            <template v-else-if="item.checked == 'between'"  >
                                <!-- 数字 -->
                                <template v-if="item.type == 'number'"  >
                                    <a-input size="large"/>
                                    <a-input size="large"/>
                                </template>
                                <!-- 日期 -->
                                <template v-if="item.type == 'date'"  >
                                    <a-date-picker size="large" v-model="item.value" />
                                    <a-date-picker size="large" v-model="item.value" />
                                </template>
                                <!-- 时间 -->
                                <template v-if="item.type == 'time'"  >
                                    <a-time-picker size="large" v-model="item.value" />
                                    <a-time-picker size="large" v-model="item.value" />
                                </template>
                                <!-- 日期+时间 -->
                                <template v-if="item.type == 'dateTime'"  >
                                    <a-date-picker size="large" show-time v-model="item.value" />
                                    <a-date-picker size="large" show-time v-model="item.value" />
                                </template>
                            </template>
                        </div>
                    </div>
                </div>
                
            </div>
            <a-modal v-model="visible" title="为您的筛选起个名字" @ok="handleOk">
                <a-input size="large" v-model="nameInput" placeholder="请输入关键字"/>
            </a-modal>
            <div class="btns">
                <a-button type="primary" @click="saveScreen(0)">确定</a-button>
                <a-button  @click="resetScreen()">重置</a-button>
                <a-button class="save_btn" type="link" @click="saveScreen(1)">保存为常用筛选</a-button>
            </div>
        </div>
    </div>
</template>
<script>
// import $ from "jquery";
import {requestXml} from '../assets/js/request';
export default {
    props: {
        screenFlag: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            name: "筛选",
            data: [],
            visible: false,
            alertVisible: false,

            screenType: 0,
            nameInput: "",
            whereArr: []
        };
    },
    mounted: function(){
        this.getScreenList();
    },
    methods: {
        // 点击选中时默认第一个选中
        isChecked(e,idx) {
            console.log(e.target.checked,idx)
            this.data[idx].isChecked = e.target.checked;
            this.data[idx].checked = this.data[idx].options[0][0];
        },
        hideMask(){
            this.data = [];
            this.$emit('updateScreenFlag', false);
        },
        // 获取筛选列表
        getScreenList(){
            requestXml("/scrm/corp/getfield?type=search","GET",(res) => {
                for(var i=0;i<res.length;i++){
                    res[i].value = "";
                    res[i].isChecked = false;
                }
                this.data = res;
            })
        },
        // 输入名字的框
        showModal() {
            this.visible = true;
        },
        // 输入名字的框点击确定
        handleOk(){
            if(this.nameInput == ""){
                this.$message.info('输入的名字不能为空');
                return false;
            }else{
                this.visible = false;
                this.addScreen();
            }
        },
        resetScreen(){
            this.getScreenList();
        },
        saveScreen(type){
            var whereArr = [];
            let isAllCheked = this.data.find(
                (item) => item.isChecked == true
            );
            // console.log(isAllCheked)
            // 是否选中一个筛选条件
            if(isAllCheked == undefined){
                this.$message.info('请选择筛选条件');
                return false;
            }

            
            for(var i=0;i<this.data.length;i++){
                if(this.data[i].isChecked == true){
                    // if(this.data[i].checked == 'isnull' || this.data[i].checked == 'not isnull'){
                    //     console.log()
                    // }else{
                    //     // if(this.data[i].value == ""){
                    //     //     this.$message.info("请填写筛选条件");
                    //     //     return false
                    //     // }
                    // }
                    if(this.data[i].type == 'date' || this.data[i].type == 'dateTime'){
                        console.log(this.data[i].value)
                        this.data[i].value = this.data[i].value._d.getTime();
                    } 
                    var obj = {};
                    obj.name = this.data[i].name;
                    obj.type = this.data[i].type;
                    obj.options = this.data[i].checked;
                    obj.value = this.data[i].value;
                    
                    whereArr.push(obj);
                }
            }
            this.whereArr = whereArr;
            if(type == 0){
                this.screenType = 0;
                this.addScreen();
            }else{
                this.screenType = 1;
                this.showModal();
            }
            
        },
        addScreen(){
            requestXml("/scrm/corp/setFilter","POST",(res) => {
                this.$emit('updateScreen', res);
                this.$emit('updateScreenFlag', false);
            },{ "type": this.screenType,"name": this.nameInput,"where": this.whereArr})
        }
    },
};
</script>
<style>
    .mask .slider .content{
        color: #333;
    }
    .mask .slider .content .screen_content{
        padding: 0 10px;
        margin: 0 auto;
        background: #F5F5F5;
    }
    .mask .slider .content .tag_content{
        height: auto;
        background-color: #fff;
    }
    .mask .slider .content .screen_content label{
        display: inLine-block;
        margin: 1rem;
        font-weight: normal;
    }
    .mask .slider .content .screen_content .info{
        text-align: left;
    }
    .mask .slider .content .screen_content input,.mask .slider .content .screen_content .ant-calendar-picker,.mask .slider .content .screen_content .ant-select{
        width: 100%;
        /* background-color: #fff; */
        margin: 8px 0;
    }
    .mask .slider .content .screen_content .ant-calendar-picker input{
        width: 100%;
    }
    .mask .slider .btns{
        border-top: 1px solid #eee;
    }
    .mask .slider .btns button:first-child{
        background-color: #36A3FF;
        margin: 2rem;
    }
    .mask .slider .btns button:last-child{
        color: #36A3FF;
        float: right;
        margin-top: 2rem;
    }

    @media screen and (min-width: 750px){
        .mask .slider .content .screen_content{
            padding: 5px 15px;
        }
        .mask .slider .content .screen_content label{
            font-size: 16px;
            margin: 5px;
        }
        .mask .slider .content .screen_content .info{
            width: 200px;
        }
        .mask .slider .btns button:first-child{
            background-color: #36A3FF;
            margin: 20px;
        }
        .mask .slider .btns button:last-child{
            margin-top: 20px;
        }
    }
</style>
